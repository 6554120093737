<script >
import DBStore from '@/core/db_store';
import { computed, defineComponent, h, onMounted, onUnmounted, ref, resolveDirective, watch, withDirectives } from 'vue'

import InputMask from "@/components/InputMask/index.vue";
import { genGUID } from '@/helpers/utils';

export default defineComponent({
	props: {
		required: {
			type: Boolean,
		},

		maxlength: {
			type: Number,
			default: 0
		},

		type: {
			type: String,
			default: 'text'
		},

		onDrawOption: {
			type: Function,
			default: (item) => item.name
		},

		readonly: {
			type: Boolean,
			default: null
		},

		form: {
			type: Object,
			default: null
		},

		field: {
			type: String,
			default: ''
		},

		placeholder: {
			type: String,
			default: null
		},

		label: {
			type: [Boolean, String],
			default: true
		},

		validation: {
			type: Boolean,
			default: true
		},

		valueField: {
			type: String,
			default: 'id'
		},

		textField: {
			type: String,
			default: 'name'
		},

		typeahead: {
			type: Boolean,
			default: true
		},

		searchAway: {
			type: Boolean,
			default: false
		},

		delay: {
			type: Number,
			default: 250
		},

		clearTrigger: {
			type: Boolean
		},

		mask: {
			type: String,
			default: null
		}
	},

	directives: {
		input: {
			mounted(el, binding) {
				if (!binding.value.input) binding.value.input = el;
			}
		},
		searchbox: {
			mounted(el, binding) {
				let elem = el.getElementsByClassName('form-control');
				if (elem.length > 0) binding.value.searchbox = elem[0];
			}
		},
		active: {
			mounted(el, binding) {
				if (binding.value) binding.value.items.push(el);
			},
			unmounted(el, binding) {
				const items = binding.value.items;
				const index = items.indexOf(el);
				if (index != -1) items.splice(index, 1);
			}
		}
	},

	setup(props, { emit }) {

		let data = props.form.store.data;

		const field = props.form.store.state.fields[props.field];

		const labelId = `${props.field}_` + genGUID();

		const label = ref(typeof props.label == 'boolean' ? field.description : props.label);

		if (!field) {
			console.error(`DBEdit: Не найдено поле(${props.field})!`);

			return () => null
		}

		const searchText = ref('');

		const verified = ref(false);
		const valid = ref(false);
		const feedback = ref('');

		const focused = ref(false);

		const show = ref(false);
		const clickaway_close = ref(false);

		const dropDown = {
			searchbox: null,
			items: [],
			input: null
		};

		const typeahead = field?.options?.typeahead;
		const select = field?.options?.select;

		const selectMode = !!field.type?.reference || !!(field.type?.enum && !field?.options?.radio);

		const storeDropDown = new DBStore(typeahead?.table ? typeahead.table : field.type?.reference ? field.type.reference : props.field);

		const readonly = computed(() => typeof props.readonly == 'boolean' ? props.readonly : props.form.store.state.readonly || props.form.readonly.value || props.field == props.form.store.model.key);

		const clearDepends = () => {
			for (const key in props.form.store.state.fields) {
				const field = props.form.store.state.fields[key];
				if (field?.depends == props.field) {
					data[key] = null;
					if (`_${key}` in data) data[`_${key}`] = null;
				}
			}
		}

		const clearValue = () => {
			data[props.field] = null;

			if (typeof field.type == 'object') {
				if (field.type.reference || field.type.enum) {
					delete data[`_${props.field}`];
				}
			}

			clearDepends();
		}

		const onKeydown = async (e) => {
			switch (e.keyCode) {
				case 13:
					if (field.type != 'TEXT') e.preventDefault();

					props.form.nextControl(e);

					break;

				case 119://F8
					e.preventDefault();

					if (!selectMode) clearValue();

					break;

				default:

					break;
			}
		}

		const validation = () => {
			if (props.validation && field.validation) {
				const value = data[props.field];

				for (const i in field.validation) {
					const result = field.validation[i](value, data);
					if (result !== false) {
						verified.value = true;
						valid.value = false;
						feedback.value = result;

						return result;
					}
				}
			}

			verified.value = false;
			valid.value = true;
			feedback.value = '';

			return false;
		}

		const closePopup = () => {
			if (clickaway_close.value) {
				show.value = false;
			}

			clickaway_close.value = true;
		}

		const openPopup = () => {
			if (readonly.value) return;

			if (show.value) {
				clickaway_close.value = true;

				closePopup();
			} else {
				show.value = true;

				searchText.value = '';

				if (!typeahead && storeDropDown) {
					if (storeDropDown.model.subtable) {
						if (field.type.enum) {
							storeDropDown.loadData(field.type.enum);

							const position = storeDropDown.data.rows.findIndex(el => el.id == data[props.field]);
							if (position != -1) setPosition(position);
						}
					} else {
						const select = field?.options?.select ? field.options.select : {};

						const fetchOptions = Object.assign({
							limit: 20,
							id: data[props.field] ? data[props.field] : null
						}, select?.params ? select.params : {});

						if (fetchOptions.filters) {
							fetchOptions.filters = typeof fetchOptions.filters == 'function' ? fetchOptions.filters(props.form.store.data) : fetchOptions.filters;
						}

						storeDropDown.fetchData(fetchOptions);
					}
				}
			}
		}

		const onBlur = () => {
			focused.value = false;

			field?.options?.onBlur && field.options.onBlur(data[props.field], data);

			return verified.value && !valid.value && validation();
		}

		const onFocus = () => {
			if (!selectMode) focused.value = true;
		}

		const clickawayClose = (event) => {
			clickaway_close.value = false;

			const path = event.path || (event.composedPath ? event.composedPath() : undefined);

			if (path) {
				for (const item of path) {
					if (item?.classList?.contains('dropdown-item')) closePopup();
				}
			}
		}

		const createInput = (config = {}) => {
			const f_input = resolveDirective('input');

			const settings = {
				value: data[props.field],

				type: props.type,

				readonly: readonly.value,

				autocomplete: 'off',

				maxlength: props.maxlength > 0 ? props.maxlength : null,

				class: {
					'input-field__input': true
				},

				mask: null,

				onKeydown,

				onInput: () => { },

				onClick: () => selectMode && openPopup(),

				onBlur,
				onFocus,

				style: {
					cursor: selectMode ? 'pointer' : 'text'
				}
			}

			let component = 'input';

			// console.log(field.type);

			if (typeof field.type == 'object') {
				settings.readonly = true;

				if (field.type.reference) {
					settings.onClick = () => openPopup();

					settings.value = data[`_${props.field}`];
				} else if (field.type.enum) {
					if (field?.options?.radio) {
						const items = [];

						if (!(typeof props.label == 'boolean' && !props.label)) {
							items.push(
								h(
									'div',
									{ class: 'radio-field__placeholder' },
									[
										label.value,
										props.required || (props.validation && field.validation && Object.keys(field.validation).includes('isRequired')) ? h('span', { class: 'radio-field__placeholder--required' }, '*') : null
									]
								)
							);
						}

						for (const i in field.type.enum) {
							items.push(
								h(
									'div',
									{ class: "radio-field__input" },
									[
										h(
											'input',
											{
												type: 'radio',
												name: props.field,
												value: field.type.enum[i].id,
												id: `${props.field}_${i}`,
												checked: field.type.enum[i].id == data[props.field],
												disabled: props.readonly || props.form.store.state.readonly,
												onClick: (e) => {
													props.form.store.data[props.field] = e.target.value;

													const _enum = field.type.enum.find(el => el.id == e.target.value);
													if (_enum) props.form.store.data[`_${props.field}`] = _enum.name;
												}
											}
										),
										h('label', { for: `${props.field}_${i}` }, field.type.enum[i].name)
									]
								)
							);
						}

						items.push(h('div', { class: "radio-field__error" }, feedback.value));

						return h('div', { class: 'radio-field' }, items);
					} else {
						settings.value = data[`_${props.field}`];
						settings.onClick = () => openPopup()
					}

				} else if (field.type.sql) {

				} else if (field.type.table) {

				}
			} else if (typeof field.type === 'string') {
				settings.onInput = (e) => {
					data[props.field] = e.target.value;
				}

				switch (field.type) {
					case 'UUID':
						settings.type = 'text';
						settings.mask = 'hhhhhhhh-hhhh-hhhh-hhhh-hhhhhhhhhhhh';

						break;

					case 'DECIMAL':
						settings.type = 'number';

						break;

					case 'SMALLINT':
						settings.type = 'number';

						break;

					case 'INTEGER':
						settings.type = 'number';

						break;

					case 'BIGINT':
						settings.type = 'number';

						break;

					case 'DATE':
						settings.type = 'datetime-local';

						break;

					case 'DATEONLY':
						settings.type = 'date';

						break;

					case 'TIME':
						settings.type = 'time';

						break;

					case 'BOOLEAN':
						return h(
							'div',
							{ class: 'checkbox-field' },
							[
								h(
									'input',
									{
										type: "checkbox",
										id: labelId,
										disabled: settings.readonly || props.form.store.state.readonly,
										checked: data[props.field],
										name: props.field,
										onClick: (event) => {
											data[props.field] = event.target.checked
										}
									}
								),
								h('label', { for: labelId }, label.value)
							]
						);

					case 'TEXT':
						component = 'textarea';

						break;
					default:
				}
			} else return null;

			Object.assign(settings, config);

			// console.log(component, settings);

			if (props.mask) {
				settings.mask = props.mask;
				return withDirectives(h(InputMask, settings), [[f_input, dropDown]]);
			} else if (settings.mask) {
				return withDirectives(h(InputMask, settings), [[f_input, dropDown]]);
			} else {
				return withDirectives(h(component, settings), [[f_input, dropDown]]);
			}
		}

		onMounted(() => {
			document.addEventListener("click", closePopup);

			if (field.validation && Object.keys(field.validation).length)
				props.form.addValidation(validation);
		})

		onUnmounted(() => {
			document.removeEventListener("click", closePopup)

			if (field.validation && Object.keys(field.validation).length)
				props.form.delValidation(validation);
		})

		const setData = (value) => {
			if (!!typeahead) {
				data[props.field] = value[props.textField];

				if (typeahead?.onSelect) typeahead.onSelect(value, props.form.store);

				dropDown.input && dropDown.input.focus();

				closePopup();
			} else {
				data[props.field] = value[props.valueField];
				data[`_${props.field}`] = value[props.textField];

				if (select?.onSelect) select.onSelect(value, props.form.store);

				setPositionData(value);
			}

			validation();
		}

		const setPosition = (position) => {
			setTimeout(() => {
				storeDropDown.setPosition(position);
				if (dropDown.items.length) dropDown.items[position].focus();
			}, 10);
		}

		const setPositionData = (data) => {
			const row = storeDropDown.data.rows.findIndex(el => el.id == data[props.valueField]);

			setPosition(row != -1 ? row : 0);
		}

		const renderDropDown = () => {
			const searchBtn = true;

			const items = [];

			if (typeof field.type === 'object' && field.type.reference) {
				items.push(h(
					'div',
					{
						class: {
							'input-search': true,
							'input-field-container': true,
							'input-search-container': searchBtn
						}
					},
					[
						h('input', {
							type: 'text',
							placeholder: 'Введите строку для поиска...',
							value: searchText.value,
							onInput: (e) => searchText.value = e.target.value
						}),
						searchBtn ? h(
							'div',
							{ class: "input-search-controls" },
							[
								h('button', { type: 'button', class: 'input-search-btn' })
							]
						) : null
					]
				))
			}

			return [
				...items,
				h('ul', { class: 'hints' },
					storeDropDown.data.rows.map((item, i) =>
						withDirectives(
							h(
								'li',
								{
									tabindex: -1,
									class: {
										'dropdown-item': true,
										'active': storeDropDown.data.position == i
									},
									onClick: () => setData(item)
								}, props.onDrawOption(item)
							),
							[[resolveDirective('active'), dropDown]]
						)
					)
				)
			];
		}

		if (typeof field.type == 'object') {
			if (field.type.enum) {
				const config = {
					key: props.valueField,
					fields: {
						[props.valueField]: {
							type: 'SMALLINT'
						},
						[props.textField]: {
							type: 'STRING'
						}
					}
				}

				storeDropDown.model.createModel(config);

				storeDropDown.createState(config);

				storeDropDown.loadData(field.type.enum);
				storeDropDown.model.subtable = true;
			} else if (field.type.reference) {
				const select = field?.options?.select ? field.options.select : {};

				watch(
					() => searchText.value,
					async (searchtext) => {
						const fetchOptions = Object.assign({
							limit: 20,
							searchtext
						}, select?.params ? select.params : {});

						if (fetchOptions.filters) {
							fetchOptions.filters = typeof fetchOptions.filters == 'function' ? fetchOptions.filters(props.form.store.data) : fetchOptions.filters;
						}

						await storeDropDown.fetchData(fetchOptions);
					}
				);
			}
		}

		watch(
			() => props.label,
			(newValue) => label.value = newValue
		)

		return () => {
			//Необходимо для обновления ссылки на дату при рендере
			data = props.form.store.data;

			if (typeof field.type === 'object' && field.type.enum && field?.options?.radio) {
				return createInput();
			} else if (typeof field.type === 'string' && field.type == 'BOOLEAN') {
				return createInput();
			} else {
				const options = {};

				if (props.typeahead && typeahead) {
					let handle = null;

					options.onInput = (e) => {
						const searchtext = e.target.value;

						if (field.type?.reference) {
							data[props.field] = null;
							data[`_${props.field}`] = searchtext;
						} else {
							data[props.field] = searchtext;
						}

						if (searchtext) {
							if (handle != null) clearTimeout(handle);

							handle = setTimeout(() => {
								const fetchOptions = Object.assign({
									searchtext,
									limit: 5,
									fields: []
								}, typeahead?.params ? typeahead.params : {});

								if (fetchOptions.filters) {
									fetchOptions.filters =
										typeof fetchOptions.filters == 'function' ? fetchOptions.filters(props.form.store.data) : fetchOptions.filters;
								}

								storeDropDown.fetchData(fetchOptions).then(data => {
									if (!!data.length) {
										show.value = true;
										clickaway_close.value = true;
									} else {
										closePopup();
									}
								});
							}, props.delay);
						} else {
							show.value = false;
						}
					}
				}

				return withDirectives(
					h(
						'div',
						{
							class: {
								'select-field': typeof field.type === 'object' && (field.type.enum || field.type.reference),
								'input-field--invalid': verified.value && feedback.value,
								'input-field': true,
								'input-field--active': focused.value || data[props.field] || (typeof field.type === 'string' && (field.type == 'DATE' || field.type == 'DATEONLY' || field.type == 'TIME'))
							},
							onKeydown: (event) => {
								const data = storeDropDown.data;

								switch (event.keyCode) {
									case 13:
										if (show.value) {
											setData(storeDropDown.currentData());

											closePopup();
										}

										event.preventDefault();

										break;

									case 38://up
										if (show.value) {
											if (data.position != null && data.position > 0) {
												setPosition(data.position - 1);
											} else {
												closePopup();
											}
										}

										event.preventDefault();

										break;

									case 40://down
										if (show.value) {
											if (data.position != null && data.position < data.rows.length - 1) {
												setPosition(data.position + 1);
											}
										}

										event.preventDefault();

										break;
								}
							}
						},
						[
							h(
								'div',
								{ class: 'input-field__placeholder' },
								[
									label.value,
									props.required || (props.validation && field.validation && Object.keys(field.validation).includes('isRequired')) ? h('span', { class: "input-field__placeholder--required" }, '*') : null
								]
							),

							createInput(options),

							h('div', { class: "input-field__error" }, feedback.value),

							props.clearTrigger ? h('div', { class: "input-field__controls" }, [
								h('button', { type: 'button', class: "input-field__clear", onClick: clearValue })
							]) : null,

							show.value ? h('div', { class: "input-field__hints" }, renderDropDown()) : null
						]
					),
					[[resolveDirective('clickaway'), clickawayClose]]
				);
			}
		}
	}
})
</script>
