<template>
	<template v-if="isLogin">
		<div class="main__header-chat" :class="{'main__header-chat--open': show}" @click.prevent="openPopup">
			<button class="main__header-chat_button"></button>
			<div class="main__header-chat-counter">{{messages.length}}</div>
		</div>
		<div class="main__header-chat-menu" v-show="show" v-clickaway="clickawayClose">
			<div class="main__header-chat-menu__body chat-menu__items">
				<div class="main__header-chat-menu__title">Уведомления</div>
				<div class="main__header-chat-menu__item" v-for="message in messages" :key="message.id">
					<div class="comments__header">
						<div class="comments__avatar">
							<img :src="avatar(message)" class="img-responsive" />
						</div>
						<div class="comments__user">
							<div class="comments__name">{{ message.authorname }}</div>
							<div class="comments__time">{{ timeago(message.updated_at) }}</div>
						</div>
					</div>
					<router-link :to="message.route">
						<p class="comments__text">{{ message.message }}</p>
					</router-link>
				</div>
			</div>
			<div class="main__header-chat-menu__footer" title="Все сообщения">
				<router-link class="main__header-chat-menu__more" to="/messages"></router-link>
			</div>
		</div>
	</template>
</template>

<script>
import { computed, defineComponent, onMounted } from 'vue'
import { config } from "@/config";
import { timeago } from '@/helpers/utils';
import { clickaway } from "@/mixins";

import stateStore from '@/store'

export default defineComponent({
	mixins: [clickaway],

	methods: {
		clickawayClose(event) {
			this.clickaway_close = false;

			const path = event.path || (event.composedPath ? event.composedPath() : undefined);

			if (path) {
				for (const item of path) {
					if (item?.classList?.contains('main__header-chat-menu__item') || item?.classList?.contains('main__header-chat-menu__more')) this.closePopup();
				}
			}
		}
	},

	setup() {
		const avatar = (message) => `${config.server}/api/images/avatar/${message.avatar}`;

		onMounted(() => {
			stateStore.dispatch('messagesRead', 20);
		})

		return {
			isLogin: computed(() => stateStore.state.token && stateStore.state.user.exp),
			messages: computed(() => stateStore.state.messages),
			timeago,
			avatar
		}
	}
})
</script>

<style>
.chat-menu__items {
	min-width: 100%;
	max-width: 100%;
	max-height: 300px;
	overflow-y: auto;
}
</style>