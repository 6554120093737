<template>
	<div class="input-field" :class="{'input-field--active': value && value.length > 0 || focused || date, 'input-field--invalid': bad || invalid, 'input-field--controls': clearTrigger}">
		<div class="input-field__placeholder">
			{{placeholder}}
			<span class="input-field__placeholder--required" v-if="required">*</span>
		</div>

		<input
			class="input-field__input"
			autocomplete="off"
			:type="typeField"
			:maxlength="maxlength"
			v-model="value"
			@focus="focused = true"
			@blur="focused = false"
			@keydown="onKeydown"
			@keyup="onKeyup"
			:readonly="readonly"
		/>

		<div class="input-field__error">{{ error }}</div>

		<div class="input-field__controls">
			<button
				type="button"
				:title="viewPassword ? 'Скрыть пароль' : 'Показать пароль'"
				:class="viewPassword ? 'input-field__eye-close' : 'input-field__eye-open'"
				v-if="showpassword"
				@click="showPassword"
			></button>
			<button type="button" class="input-field__clear" v-if="clearTrigger" @click="clearInput"></button>
		</div>
	</div>
</template>

<script >
import { computed, defineComponent, ref, watch } from 'vue'

export default defineComponent({
	props: {
		required: Boolean,
		readonly: Boolean,

		placeholder: {
			type: String
		},

		maxlength: {
			type: [String, Number],
			default: 255
		},

		email: Boolean,

		password: Boolean,

		showpassword: Boolean,

		date: Boolean,

		bad: {
			type: Boolean,
			default: false
		},

		error: {
			type: String,
			default: 'Обязательное поле'
		},

		onDrawOption: {
			type: Function,
			default: (item) => item.name
		},

		modelValue: {
		},

		clearTrigger: {
			type: Boolean,
			default: false
		}
	},

	emits: ['update:modelValue'],

	setup(props, { emit }) {
		const focused = ref(false);
		const invalid = ref(false);
		const value = ref('');

		const viewPassword = ref(false);


		watch(
			() => props.modelValue,
			(newValue) => value.value = newValue,
			{
				immediate: true
			}
		)

		const onKeyup = () => !(props.modelValue == undefined || props.modelValue == null) && emit('update:modelValue', value.value)

		const clearInput = () => value.value = null;

		const typeField = computed(() => {
			if (props.password) {
				return props.showpassword ? (viewPassword.value ? 'text' : 'password') : 'password';
			} else if (props.email) {
				return 'email';
			} else if (props.date) {
				return 'date';
			} else return 'text';
		})

		const showPassword = () => {
			viewPassword.value = !viewPassword.value;
		}

		return {
			focused,
			invalid,
			value,

			onKeyup,
			clearInput,
			showPassword,
			viewPassword,

			typeField
		}
	}
})
</script>
