import dayjs from 'dayjs';

import { reactive } from 'vue';

import { genGUID, mergeDeep } from '@/helpers/utils';

import Model from "./model";

class Store {
	public owner: any = null;
	public state: any = reactive({});
	public files: any = {};
	public model: Model = new Model;

	createState(config: any = {}) {
		const state: any = { fields: {}, readonly: false, loaded: false };

		const _config = Object.assign({}, config);

		//Создание полей id, owner если они не найдены
		if (_config.fields) {
			if (this.model.key && !_config.fields[this.model.key]) {
				_config.fields[this.model.key] = {
					description: this.model.key,
					type: 'UUID',
					options: {
						visible: false
					}
				};
			}

			if (this.model.ownerField && !_config.fields[this.model.ownerField]) {
				_config.fields[this.model.ownerField] = {
					description: this.model.ownerField,
					type: 'UUID',
					options: {
						visible: false
					}
				};
			}
		}

		if (_config.fields && Object.keys(_config.fields).length > 0) {
			for (const key in _config.fields) {
				const field = this.model.fields[key] ? this.model.fields[key] : {};

				state.fields[key] = mergeDeep(
					{
						options: {
							visible: true
						}
					},
					field,
					_config.fields[key]
				);

				if (!state.fields[key].description) state.fields[key].description = key;
			}
		} else {
			for (const key in this.model.fields) {
				const field = this.model.fields[key];

				state.fields[key] = mergeDeep(
					{
						options: {
							visible: true
						}
					},
					field
				);

				if (!state.fields[key].description) state.fields[key].description = key;
			}
		}

		Object.assign(this.state, state);
	}

	getFetchFields() {
		const readFields = (fields: any): any[] => {
			const result = [];

			for (const key in fields) {
				const field = fields[key];

				if (typeof field.type === 'string') {
					result.push(key);
				} else if (field.fields) {
					const fields = readFields(field.fields);

					if (this.model.key && !fields.includes(this.model.key)) fields.push(this.model.key);
					if (this.model.ownerField && !fields.includes(this.model.ownerField)) fields.push(this.model.ownerField);

					result.push({
						[key]: {
							fields
						}
					})
				} else {
					result.push(key);
				}
			}

			return result;
		}

		return readFields(this.state.fields);
	}

	createData(data: any) {
		const newRow: any = {};

		if (this.model.key && !data[this.model.key]) newRow[this.model.key] = genGUID();

		for (const key in data) {
			if (key in this.model.fields) {
				const field = this.model.fields[key];

				//Загрузка объектных полей
				if (typeof field.type == 'object') {
					//Табличное поле
					if (field.type.table) {

					} else if (field.type.reference) {
						newRow[key] = data[key];
						newRow[`_${key}`] = data[`_${key}`];

						//Перечисление
					} else if (field.type.enum) {
						newRow[key] = data[key];

						if (data[`_${key}`]) {
							newRow[`_${key}`] = data[`_${key}`];
						} else {
							const _enum = field.type.enum.find((el: any) => el.id == data[key]);
							if (_enum) {
								newRow[`_${key}`] = _enum.name;
							}
						}

					} else if (field.type.sql) {
						newRow[key] = data[key];
					}
				} else {
					//Примитивное поле
					if (field.type == 'DATE') {
						newRow[key] = data[key] ? dayjs(data[key]).format("YYYY-MM-DDTHH:mm") : null;
					} else {
						newRow[key] = data[key];
					}
				}
			}
		}

		if (this.owner) newRow[this.model.ownerField] = this.owner;

		for (const key in data) {
			if (key in this.model.fields) {
				const field = this.model.fields[key];

				//Загрузка объектных полей
				if (typeof field.type == 'object') {
					//Табличное поле
					if (field.type.table) {
						const config = this.state.fields[key] ? this.state.fields[key] : {};

						newRow[key] = [];

						const store = new Store;
						store.model = field.type.model;
						store.createState(config);

						if (store.model.ownerField && store.model.depends && data[store.model.depends]) store.owner = data[store.model.depends];

						const rows = Array.isArray(data[key]) ? data[key] : data[key].rows;

						for (const row of rows) {
							newRow[key].push(store.createData(row));
						}
					}
				}
			}
		}

		return newRow;
	}

	getData(data: any) {
		const result: any = {};

		for (const key in data) {
			const field = this.model.fields[key];

			if (field) {
				if (typeof field.type == 'object') {
					//Табличное поле
					if (field.type.table) {
						const store = new Store;
						store.model = field.type.model;

						result[key] = [];

						for (const row of data[key]) {
							result[key].push(store.getData(row));
						}

						//Ссылочное поле
					} else if (field.type.reference) {
						result[key] = data[key];

						//Перечисление
					} else if (field.type.enum) {
						result[key] = data[key];
					}
				} else {
					if (field.type == 'DATE') {
						result[key] = data[key] ? dayjs(data[key]).format("YYYY-MM-DDTHH:mm:ssZ") : null;
					} else {
						result[key] = data[key];
					}
				}
			}
		}

		return result;
	}
}

export default Store;
