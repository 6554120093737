
import { reactive, onMounted, onUnmounted } from 'vue';

const contextMenu = reactive({
	items: [],
	show: false,
	top: '0px',
	left: '0px'
});

export const open = (event: any, items: any = []) => {
	event.preventDefault();

	if (items.length > 0) {
		setTimeout(() => {
			contextMenu.items = items;
			contextMenu.top = `${event.pageY}px`;
			contextMenu.left = `${event.pageX}px`;
			contextMenu.show = true;
		}, 0)
	}
}

export default {
	setup() {
		const closeMenu = () => contextMenu.show = false;

		const handleEscapeKey = (e: any) => contextMenu.show && e.keyCode == 27 && closeMenu();

		onMounted(() => {
			document.body.addEventListener('keyup', handleEscapeKey)
			document.addEventListener("click", closeMenu);
		})

		onUnmounted(() => {
			document.body.removeEventListener('keyup', handleEscapeKey)
			document.removeEventListener("click", closeMenu);
		})

		return {
			contextMenu
		}
	}
}
