<template>
	<div class="page-wrapper">
		<main class="main index">
			<slot />
		</main>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "Default"
})
</script>

<style>
</style>