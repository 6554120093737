import { createToast } from 'mosha-vue-toastify';

import API from '@/core/api';

import WS from '@/core/wsclient';

import router from "@/router";

import { loadSchemas } from '@/tables';
import api from '@/core/api';

export default {
	async login({ commit }: { commit: any }, { token, user }: { token: any, user: any }) {
		if (user.exp) {
			const date = new Date;

			user.exp = date.getTime() + user.exp * 1000;
		}

		commit('token', token);
		commit('timeout', 0);
		commit('user', user);

		commit('loaded', false);

		const resSchemas: any = await API.dbSchemas();
		if (resSchemas.status == 200) {
			loadSchemas(resSchemas.data);
			commit('loaded', true);
		}

		const response: any = await api.questionnaire();

		if (response.status == 200) {
			if (response.data.questionnaire) {
				router.push("/statements");
			} else {
				router.push("/questionnaire");
			}
		}

		const constants: any = await api.constants();

		if (constants.status == 200) {
			commit("setConstants", constants.data);
		}

		WS.send({ event: 'login', data: token });
	},

	async status({ commit, state }: { commit: any, state: any }) {
		const response: any = await API.status();
		if (response.status == 200) {
			commit('loaded', false);

			if (response.data.exp) {
				const date = new Date;

				response.data.exp = date.getTime() + response.data.exp * 1000;
			}

			if (!response.data.id && state.token) commit('token', null);

			commit('user', response.data);

			const resSchemas: any = await API.dbSchemas();
			if (resSchemas.status == 200) {
				loadSchemas(resSchemas.data);
				commit('loaded', true);
			}

			const constants: any = await api.constants();

			if (constants.status == 200) {
				commit("setConstants", constants.data);
			}

			WS.send({ event: 'login', data: state.token });

			return response.data;
		} else {
			return null;
		}
	},

	async logout({ commit }: { commit: any }) {
		commit('loaded', false);
		commit('token', null);
		commit('timeout', 0);

		if (window.location.pathname != '/login') router.push("/login");

		const response: any = await API.status();

		if (response.status == 200) {
			commit('user', response.data);

			const resSchemas: any = await API.dbSchemas();
			if (resSchemas.status == 200) {
				loadSchemas(resSchemas.data);

				commit('loaded', true);
			}
		}

		WS.send({ event: 'logout' });
	},

	proceedToken({ commit, state }: { commit: any, state: any }) {
		API.proceedToken().then((response: any) => {
			if (response.status == 200) {
				if (response.data.token) {
					if (response.data.exp) {
						const date = new Date;

						response.data.exp = date.getTime() + response.data.exp * 1000;
					}

					commit('timeout', 0);
					commit('token', response.data.token);
					commit('user', Object.assign(state.user, { exp: response.data.exp }));
				}
			}
		})
	},

	messagesRead({ commit }: { commit: any }) {
		API.messagesRead().then((response: any) => response.status == 200 && commit('messages', response.data));
	},

	messagesDelete({ state }: { state: any }, id: any) {
		for (const i in state.messages) {
			if (state.messages[i].id == id) {
				API.messagesDelete(id).then((response: any) => {
					if (response.status == 200) state.messages.splice(i, 1);
				})

				break;
			}
		}
	},

	newMessage({ state }: { state: any }, message: any) {
		createToast(message.message, {
			toastBackgroundColor: '#00adef', timeout: 2000, hideProgressBar: true, transition: 'zoom'
		});

		state.messages.unshift(message);
	},

	online({ commit }: { commit: any }, value: any) {
		commit('online', value);
	},

	getConstants({ commit }: { commit: any }, { state }: { state: any }) {
		api.constants().then((response: any) => {
			if (response.status == 200) {
				commit("setConstants", response.data);
			}
		})
	},
}
