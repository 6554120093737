<template>
	<div class="page-wrapper">
		<main class="main index">
			<div class="index-popup index-popup--open" v-cloak>
				<slot />
			</div>
		</main>
	</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "Default"
})
</script>

<style>
</style>