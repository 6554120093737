
import { defineComponent, computed, onUnmounted } from 'vue';

import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import ContextMenu from "@/components/ContextMenu.vue";

import DefaultLayout from "@/layouts/Default.vue";
import EmptyLayout from "@/layouts/Empty.vue";
import Locking from "@/layouts/Locking.vue";
import Layout404 from "@/layouts/404.vue";

import Modals from "@/layouts/Modals.vue";
import TimeOut from "@/views/TimeOut.vue";

export default defineComponent({
	components: {
		DefaultLayout, EmptyLayout, Layout404, Modals, TimeOut, ContextMenu, Locking
	},

	setup() {
		const route = useRoute();
		const { state, commit } = useStore();

		const handeInterval = setInterval(() => {
			if (state?.user?.exp && state.timeout == 0) {
				const date = new Date;

				const timeout = ~~((state.user.exp - date.getTime()) / 1000);

				if (timeout < 180) commit('timeout', timeout);
			}
		}, 5000);

		onUnmounted(() => clearTimeout(handeInterval));

		return {
			load: computed(() => state.load),
			timeout: computed(() => state.timeout),
			layout: computed(() => route.meta.layout || "layout-404")
		}
	}
})
