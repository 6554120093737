<template>
	<template v-if="isLogin">
		<a class="main__header-user" :class="{'main__header-user--open': show}" @click.prevent="openPopup">
			<span class="main__header-user-name">{{userName}}</span>
			<div class="main__header-avatar">
				<div class="main__header-avatar-image">
					<img :src="userAvatar" :alt="userName" class="img-responsive" />
				</div>
				<div class="main__header-avatar--online" v-if="userOnline"></div>
			</div>
		</a>
		<ul class="main__header-user-menu" v-show="show">
			<li>
				<a href="#" class="main__header-user-menu--logout" @click.stop.prevent="onLogout">Выход</a>
			</li>
		</ul>
	</template>
	<template v-else>
		<router-link to="/login" class="main__header-user-menu--logout">
			<div class="main__header-user">
				<i class="icon-enter icon-2x"></i> Вход
			</div>
		</router-link>
	</template>
</template>

<script>
import { computed, defineComponent } from 'vue';
import { clickaway } from "@/mixins";

import { config } from '@/config';

import stateStore from '@/store'

export default defineComponent({
	mixins: [clickaway],

	methods: {
		onLogout() {
			stateStore.dispatch('logout');

			this.closePopup();
		}
	},

	setup() {
		return {
			isLogin: computed(() => stateStore.state.token && stateStore.state.user.exp),
			userName: computed(() => stateStore.state.user.name),
			userOnline: computed(() => stateStore.state.online),
			userAvatar: computed(() => `${config.server}/api/images/avatar/${stateStore.state.user.avatar ? stateStore.state.user.avatar : null}`)
		}
	}
})
</script>
