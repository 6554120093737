<script>
import { computed, defineComponent, h, onMounted, resolveComponent } from 'vue';

import dayjs from "dayjs";
import stateStore from "@/store";
import { genGUID } from '@/helpers/utils';

export default defineComponent({
	props: {
		store: {
			type: Object,
			default: null
		},
		config: {
			type: Object,
			default: {}
		},
		data: {
			type: Object,
			default: {}
		},
		defaults: {
			type: Object,
			default: {}
		},
		copy: {
			type: Boolean,
			default: false
		},
		readonlyForm: {
			type: Boolean,
			default: false
		}
	},

	methods: {
		createForm() {
			const fields = this.store.state.fields;

			const result = [];

			for (const key in fields) {
				const field = fields[key];

				if (!('visible' in field.options && !field.options.visible)) {
					const elements = [];

					if (typeof field.type == 'object' && field.type.table) {
						result.push(h(resolveComponent('DBGrid'), { form: this, field: key }));
					} else {
						elements.push(
							h(
								resolveComponent('DBEdit'),
								{
									form: this,
									field: key,
									label: !(typeof field.type == 'string' && field.type == 'BOOLEAN')
								}
							)
						);

						result.push(
							h(
								'div',
								{ class: 'form-row' },
								h('div', { class: 'col' }, elements)
							)
						)
					}
				}
			}

			return result;
		}
	},

	computed: {
		readonly: {
			get() {
				return this.store.state.readonly;
			},
			set(value) {
				this.store.state.readonly = value;
			}
		}
	},

	render() {
		if (this.store.state.loaded) {
			return h('form', { name: this.name }, this.$slots.default ? this.$slots.default(this) : this.createForm());
		} else {
			return null;
		}
	},

	setup(props) {
		const validators = [];

		const name = `form_${genGUID()}`;

		/**
		 * Добавление валидатора для едита, вызывается из едита
		 */
		const addValidation = (validation) => validators.push(validation);

		const delValidation = (validation) => {
			for (const key in validators) {
				if (validators[key] == validation) {
					validators.splice(key, 1);

					break;
				}
			}
		};

		/**
		 * Вызывается из едитов для перевода фокуса на следующее поле
		 */
		const nextControl = (target) => {
			if (target.target.nodeName == 'INPUT') {
				// const elements = document.forms[name].getElementsByTagName('input');
				const elements = document.forms[name].getElementsByClassName('input-field__input');

				if (elements.length) {
					for (const key in elements) {
						const element = elements[key]
						if (element == target.target) {
							let thisControl = +key;

							do {
								thisControl++;
								if (thisControl >= elements.length) thisControl = 0;
							} while (!(elements[thisControl].offsetWidth > 0 || elements[thisControl].offsetHeight > 0));

							elements[thisControl].focus();

							break;
						}
					}
				}
			}
		}

		/**
		* Добавление в стор blob данных
		* @param blob - BLOB данные
		* @param name - имя файла
		* @param filesTable имя табличного поля в сторе
		* @param id - ключ записи
		*/
		const addBlob = (blob, name, tableFiles = 'files', id = genGUID()) => {
			props.store.files[id] = blob;

			const storeSetPlan = props.store.createDBStore(tableFiles);

			return storeSetPlan.push({
				id,
				name,
				size: blob.size,
				type: blob.type,
				lasteditor: stateStore.state.user['id'],
				_lasteditor: stateStore.state.user['name'],
				updated_at: dayjs().format(),
				author: stateStore.state.user['id'],
				_author: stateStore.state.user['name'],
				created_at: dayjs().format()
			});
		}

		onMounted(async () => {
			stateStore.state.load = true;

			const readonly = props.store.state.readonly;

			props.store.createState(props.config);

			props.store.state.readonly = readonly || props.readonlyForm;

			if (props.store.model.subtable) {
				if (props.data[props.store.model.key]) {
					props.store.loadData(props.data);
				} else {
					props.store.loadData(
						Object.assign(
							{
								[props.store.model.key]: genGUID()
							},
							props.data,
							props.defaults
						)
					);

					props.store.isNew.value = true;
				}
			} else {
				if (props.data[props.store.model.key]) {
					if (props.copy) {
						await props.store.copyData(props.data[props.store.model.key]);

						props.store.isNew.value = true;
					} else {
						await props.store.fetchData(props.data[props.store.model.key]);
					}
				} else {
					const notion = {};

					if (props.store.owner && props.store.model.depends && props.store.model.ownerField) {
						notion[props.store.model.ownerField] = props.store.owner;
					}

					const data = await props.store.notion(
						Object.assign(
							{
								[props.store.model.key]: genGUID()
							},
							props.data,
							props.defaults,
							notion
						)
					);

					props.store.isNew.value = true;

					props.store.loadData(data);

					//Протестить
					// props.store.isNew.value = true;
				}
			}

			if (props?.config?.onCreate) {
				props.config.onCreate(props.store);
			}

			props.store.setWatching();

			stateStore.state.load = false;
		})

		/**
		 * Валидация формы
		 */
		const validation = () => {
			let result = true;

			for (const validator of validators) {
				if (validator() !== false) {
					result = false;
				}
			}

			return result;
		}

		/**
		 * Сохранение данных формы
		 */
		const save = async () => {
			if (validation()) {
				stateStore.state.load = true;

				const data = await props.store.save();

				stateStore.state.load = false;

				return data;
			} else {
				return false;
			}
		}

		return {
			name,
			addValidation,
			delValidation,
			nextControl, save,
			data: props.store.data,
			loaded: props.store.state.loaded,
			addBlob,
			validation,
			isNew: computed(() => props.store.isNew.value),
			isModif: computed(() => props.store.isModif.value)
		}
	}
})
</script>
