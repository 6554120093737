import { reactive, ref, watch } from 'vue';

import Bus from '@/core/bus';
import api from '@/core/api';

import Store from "./store";
import { getSchema } from '@/tables';
import dayjs from 'dayjs';
import { b64toBlob, genGUID } from '@/helpers/utils';
import DBStore from './db_store';
import Model from './model';

class DBStoreRecord extends Store {
	public data = reactive<any>({});

	//Признак модификации стора
	public isModif = ref<boolean>(false);

	//Признак что новая запись стора
	public isNew = ref<boolean>(false);

	public onBeforeSave = (data: any) => true;
	public onAfterSave = (data: any) => true;
	public onLoadData = (data: any) => true;

	constructor(public name: string, config: any = {}) {
		super();

		const schema: any = getSchema(name);

		if (schema) {
			this.model.createModel(schema);

			this.createState(config);
		}

		Bus.$on('save-data', (saveData: any) => {
			for (const key in this.state.fields) {
				const field = this.state.fields[key];
				if (typeof field.type == 'object' && field.type.reference && saveData.table == field.type.reference) {
					const schema = getSchema(saveData.table);

					if (schema && this.data[key] == saveData.data[schema.key]) {
						this.data[`_${key}`] = saveData.data[schema.notion];
					}
				}
			}
		});

		//Слежение за изменениями в сторе
		watch(
			() => this.data,
			() => {
				this.isModif.value = true
			},
			{
				deep: true
			}
		)
	}

	async fetchData(id: string, options: any = {}) {
		if (!this.model.subtable) {
			const config: any = {
				table: this.name,
				method: 'object',
				data: { id },
				params: Object.assign({ fields: this.getFetchFields() }, options)
			};

			if (this.owner) config['owner'] = this.owner;

			const response: any = await api.dbQuery(config);

			if (response.status == 200) {
				const data = response.data?.data;

				if (data) this.loadData(data);
			}

			return this.data;
		}
	}

	async copyData(id: string) {
		if (!this.model.subtable) {
			const config: any = {
				table: this.name,
				method: 'copy',
				data: { id }
			};

			if (this.owner) config['owner'] = this.owner;

			const response: any = await api.dbQuery(config);

			if (response.status == 200) {
				const data = response.data?.data;

				if (data) this.loadData(data);
			}

			return this.data;
		}
	}

	setWatching() {
		for (const key in this.state.fields) {
			const field = this.state.fields[key];
			if (field?.options?.watch) {
				watch(
					() => this.data[key],
					(newValue, oldValue) => field.options.watch(newValue, oldValue, this)
				)
			}
		}
	}

	async notion(notionData: any = {}) {
		let getNotion = false;

		const getData = (data: any, model: any = null) => {
			for (const key in data) {
				const field = model ? model[key] : this.state.fields[key];

				if (field) {
					if (typeof field.type == 'object') {
						if (field.type.reference) {
							getNotion = true;
						} else if (field.type.table) {
							for (const row of data[key]) {
								getData(row, field.type.model.fields);
							}
						}
					}
				}
			}
		}

		getData(notionData);

		if (getNotion) {
			const response: any = await api.dbQuery({ table: this.name, method: 'notion', data: notionData });

			if (response.status == 200) return response.data.data;
		}

		return notionData;
	}

	async save(): Promise<any> {
		if (this.onBeforeSave(this.data)) {
			// console.log(this.data);

			//Если в данных есть ключевое поле но оно пустое(null), то удаляем его
			if (this.model.key in this.data && !this.data[this.model.key]) delete this.data[this.model.key];

			if (this.model.subtable) {
				Bus.$emit('save-data', { table: this.name, data: this.data, newrecord: this.isNew.value });

				this.onAfterSave(this.data);

				return this.data;
			} else {
				const saveForm = async () => {
					if (Object.keys(this.files).length > 0) {
						const formData = new FormData();
						formData.append('table', this.name);
						formData.append('data', JSON.stringify(this.getData(this.data)));
						for (const key in this.files) {
							formData.append(key, this.files[key]);
						}

						return await api.formToServer(formData);
					} else {
						return await api.dbQuery({
							table: this.name,
							method: 'save',
							data: this.getData(this.data)
						});
					}
				}

				const response: any = await saveForm();

				if (response.status == 200) {
					const data = response.data;

					if (data) {
						this.loadData(data.data);

						// this.refreschData(this.data, data.data, this.model);

						this.onAfterSave(this.data);

						Bus.$emit('save-data', { table: this.name, data: this.data, newrecord: this.isNew.value });

						for (var member in this.files) delete this.files[member];

						this.isNew.value = false;

						return this.data;
					}
				}
			}
		}

		return null;
	}

	createDBStore(name: string) {
		const field = this.state.fields[name];

		const store = new DBStore(field.table);
		store.model = field.type.model;
		store.files = this.files;

		store.createState();

		if (field.depends) {
			store.ownerData = this.data;
			store.owner = this.data[field.depends];
		}

		if (!this.data[name]) this.data[name] = [];

		store.data.rows = this.data[name];

		store.data.position = store.data.rows.length > 0 ? 0 : -1;

		return store;
	}

	//Не используется
	refreschData(data: any, newData: any, model: Model) {
		for (const key in newData) {
			if (key in model.fields) {
				const field = model.fields[key];

				if (typeof field.type == 'object') {
					if (field.type.table) {
						const tableModel = field.type.model;

						for (const row of newData[key]) {
							const _data = data[key].find((el: any) => el[tableModel.key] == row[tableModel.key]);

							if (_data) this.refreschData(_data, row, tableModel);
						}
					} else if (field.type.reference) {
						data[key] = newData[key];
						data[`_${key}`] = newData[`_${key}`];

					} else if (field.type.enum) {
						data[key] = newData[key];

						if (newData[`_${key}`]) {
							data[`_${key}`] = newData[`_${key}`];
						} else {
							const _enum = field.type.enum.find((el: any) => el.id == newData[key]);
							if (_enum) {
								data[`_${key}`] = _enum.name;
							}
						}
					} else if (field.type.sql) {
						data[key] = newData[key];
					}
				} else {
					if (field.type == 'DATE') {
						data[key] = dayjs(data[key]).format("YYYY-MM-DDTHH:mm");
					} else {
						data[key] = data[key];
					}
				}
			}
		}
	}

	loadData(data: any) {
		const loadData = (data: any, newData: any, model: Model, owner: any = null) => {
			if (model.key && !data[model.key]) data[model.key] = genGUID();

			for (const key in newData) {
				if (key == '$file') {
					this.files[newData[model.key]] = b64toBlob(newData[key], newData.type);
				}
			}

			for (const key in model.fields) {
				const field = model.fields[key];

				//Загрузка объектных полей
				if (typeof field.type == 'object') {
					//Табличное поле
					if (field.type.table) {

					} else if (field.type.reference) {
						if (key in newData) {
							data[key] = newData[key];
							data[`_${key}`] = newData[`_${key}`];
						}

						//Перечисление
					} else if (field.type.enum) {
						if (key in newData) {
							data[key] = newData[key];

							if (newData[`_${key}`]) {
								data[`_${key}`] = newData[`_${key}`];
							} else {
								const _enum = field.type.enum.find((el: any) => el.id == newData[key]);
								if (_enum) {
									data[`_${key}`] = _enum.name;
								}
							}
						}
					} else if (field.type.sql) {
						if (key in newData) data[key] = newData[key];
					}
				} else {
					//Примитивное поле
					if (field.type == 'DATE') {
						if (newData[key]) data[key] = dayjs(newData[key]).format("YYYY-MM-DDTHH:mm");
					} else {
						if (key in newData) data[key] = newData[key];
					}
				}
			}

			if (owner && model.depends && model.ownerField) {
				data[model.ownerField] = owner;
			}

			//Загрузка табличных частей
			for (const key in model.fields) {
				const field = model.fields[key];

				if (typeof field.type == 'object') {
					if (field.type.table) {
						if (!data[key]) data[key] = [];

						data[key].splice(0, data[key].length);//Чуть что убрать

						if (newData[key]) {
							const tableModel = field.type.model;

							const owner = tableModel.depends && tableModel.ownerField && data[tableModel.depends] ? data[tableModel.depends] : null;

							for (const newRow of newData[key]) {
								let dataA = data[key].find((el: any) => el && el[tableModel.key] == newRow[tableModel.key]);

								if (dataA) {
									loadData(dataA, newRow, tableModel, owner);
								} else {
									const dataA = {};

									loadData(dataA, newRow, tableModel, owner);

									data[key].push(dataA);
								}
							}
						}
					}
				}
			}
		}

		loadData(this.data, data, this.model, this.owner);

		this.onLoadData(this.data);

		setTimeout(() => this.isModif.value = false, 0);

		this.state.loaded = true;
	}
}

export default DBStoreRecord;