

import { defineComponent, reactive, watch } from 'vue'

export default defineComponent({
	emits: ['sort'],

	props: {
		reftable: {
			type: Object,
			default: null
		},
		store: {
			type: Object,
			default: {}
		},
		config: {
			type: Object,
			default: {}
		}
	},


	setup(props, { emit }) {
		let sortingFields: any = {};

		const classFields: any = reactive({});

		/**
		 * Открытие контекстного меню
		 */
		const onOpenMenu = (event: any) => {
			if (props.config.menu) {
				event.preventDefault();

			}
		}

		/**
		 * Стили для ячеек заголовка
		 */
		const getStyleTh = (fieldConfig: any) => {
			const result: any = {};

			if (fieldConfig.options?.width) {
				result['width'] = fieldConfig.options.width;
			}

			return result;
		}

		watch(
			() => props.config.orderby,
			(value) => {
				if (value.length == 0) {
					for (const key in classFields) delete classFields[key];
					sortingFields = {};
				}
			}
		)

		return {
			sortingFields,
			classFields,
			getStyleTh,
			onOpenMenu
		}
	}
})
