<template>
	<div :class="['popup', open ? 'popup--open' : '']" v-cloak>
		<button type="button" class="popup__close--mobile" @click="closePopup()"></button>
		<div class="popup__body" :style="width ? `width: ${width}` : ''">
			<div class="popup__header">
				<div class="popup__title">{{title}}</div>
				<button type="button" class="popup__close" @click="closePopup()"></button>
			</div>
			<slot :data="data"></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		width: {
			type: String,
			default: null
		},
		title: {
			type: String,
			required: true
		}
	},

	data() {
		return {
			open: false,
			data: {}
		};
	},

	methods: {
		openPopup(data = null) {
			if (data) this.data = data;

			this.open = true;

			document.body.style.overflow = 'hidden';
			document.ontouchmove = (e) => e.preventDefault();
		},

		closePopup() {
			this.open = false;

			document.body.style.overflowX = 'hidden';
			document.body.style.overflowY = 'inherit';
			document.ontouchmove = () => true;
		}
	}
}
</script>
