
import dayjs from "dayjs"

import { defineComponent, ref } from 'vue'

import { open as openContextMenu } from '@/components/ContextMenu.vue'

export default defineComponent({
	emits: ['active', 'select'],

	props: {
		reftable: {
			type: Object,
			default: null
		},
		store: {
			type: Object,
			default: {}
		},
		config: {
			type: Object,
			default: {}
		},
		crossot: {
			type: Function,
			default: () => false
		}
	},

	setup(props, { emit }) {
		const showMenu = ref(null);

		const getData = (structure: any, field: any, data: any) => {
			const calc = structure?.options?.calc;

			if (calc) return typeof calc == 'function' ? calc(data) : calc;

			let value = data[field];

			if (data[`_${field}`]) {
				value = data[`_${field}`];
			} else {
				if (typeof structure.type == 'object') {
					if (structure.type.table) {
						value = value ? `Objects [${value.length}]` : null;
					}
				} else {
					switch (structure.type) {
						case 'DECIMAL':
							const decimalPrecision = structure?.options?.decimalPrecision;

							if (decimalPrecision && decimalPrecision >= 0) value = Number(data[field]).toFixed(decimalPrecision);

							break;
						case 'DATEONLY':
							if (data[field] && data[field] != '0000-00-00') value = dayjs(data[field]).format('DD.MM.YYYY');

							break;
						case 'TIME':
							if (data[field]) value = dayjs(data[field]).format('HH:mm:ss');

							break;
						case 'DATE':
							if (data[field]) value = dayjs(data[field]).format('DD.MM.YYYY, HH:mm:ss');

							break;
						case 'BOOLEAN':
							value = !!data[field] ? '<i class="icon-checkmark4"></i>' : '';

							break;
					}
				}
			}

			return value;
		}

		const getNumbering = (numberRow: number) => props.store.fetchOptions.page * props.store.fetchOptions.limit + numberRow + 1;

		const onActive = (position: number) => emit('active', position);

		const getClassTr = (key: number, data: any) => {
			return {
				'selectrow': key == props.store.data.position,
				'table-info': key == props.store.data.position,
				'line-through': props.crossot(data)
			}
		};

		const onOpenMenu = (event: any, data: any, position: number) => {
			emit('active', position);

			props.config.menu && openContextMenu(event, props.reftable.contextMenuBody(event, data, position));

			// showMenu.value = null;
			// console.log('active');
		}

		return {
			onActive,
			onOpenMenu,
			getData,
			getNumbering,
			getClassTr,
			config: props.config,
			showMenu
		}
	}
})
