<template>
	<div>
		<div class="form__row">
			<table class="table-block" @keydown="onKeyDown" tabindex="-1">
				<GridHead :reftable="reftable" :store="store" :config="settings" v-if="settings.header"></GridHead>
				<GridBody :reftable="reftable" :store="store" :config="settings" @active="activeRow" @select="selectRow" :crossot="settings.crossot"></GridBody>
			</table>
		</div>
		<div class="application__add-btn" v-if="settings.add && !isReadonly">
			<button type="button" class="btn btn--plus" @click="addRow">Добавить</button>
		</div>
	</div>
</template>

<script lang="jsx">
import Swal from 'sweetalert2';

import { defineComponent, reactive, computed, onMounted } from "vue";

import stateStore from "@/store";
import DBStore from "@/core/db_store";

import GridHead from "./GridHead";
import GridBody from "./GridBody";
import { edit } from '../DB/db';

export default defineComponent({
	emits: ['active', 'select'],

	components: {
		GridHead, GridBody
	},

	props: {
		store: {
			type: Object,
			default: null
		},
		config: {
			type: Object,
			default: {}
		},
		readonly: {
			type: Boolean,
			default: false
		},
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: null
		}
	},

	computed: {
		reftable() {
			return this;
		}
	},

	setup(props, { emit }) {
		const settings = reactive({
			id: null,
			filters: [],
			add: true,
			header: true,
			menu: true,
			numbering: false,
			selectMode: false,
			owner: null,//id - зависимого поля
			contextMenuBody: [],
			default: {},
			config: {},//Настройки компонента редактирования
			onCreate: () => null,
			deleteText: 'Удалить запись?',
			crossot: () => false
		});

		Object.assign(settings, props.config);

		const createStore = () => {
			const field = props.form.store.model.fields[props.field];

			const store = new DBStore(field?.table ? field.table : `table_${props.field}`)
			store.model = field.type.model;
			store.files = props.form.store.files;

			store.createState(props.form.store.state.fields[props.field]);

			if (field.depends) {
				store.ownerData = props.form.store.data;
				store.owner = props.form.store.data[field.depends];
			}

			store.data.rows = props.form.store.data[props.field];
			store.data.position = store.data.rows.length ? 0 : -1;

			return store;
		}

		const store = props.store ? props.store : createStore();

		if (!store) {
			console.error('Grid storage not installed');

			return {
				access: { read: false }
			}
		}

		const access = store.model.access;

		const _readonly = !(access.create || access.update || access.delete);

		const isReadonly = computed(() => {
			if (props?.form?.readonly && typeof props.form.readonly == 'object') {
				console.log('Error grid readonly');
			}

			return _readonly || props.readonly || store.state.readonly || props?.form?.readonly?.value ? true : false
		});

		const selectRow = (data = store.currentData()) => {
			if (settings.selectMode) {
				emit('select', data);
			} else {
				editRow();
			}
		};

		const editRow = async () => {
			//Возвращать фокус на грид
			const data = store.currentData();
			if (data) {
				if (!isReadonly.value && access.update) {
					await edit({
						store: store,
						data,
						config: settings.config
					});
				} else if (isReadonly.value) {
					await edit({
						readonly: true,
						store: store,
						data,
						config: settings.config
					});
				}
			}

		}

		const addRow = async (data = {}) => {
			if (!isReadonly.value && access.create) {
				const params = Object.assign({
					store,
					data,
					config: settings.config
				});

				await edit(params);
			}
		};

		const deleteRow = (data = store.currentData()) => {
			if (!isReadonly.value) {
				if (data) {
					Swal.fire({
						title: settings.deleteText,
						showCancelButton: true,
						confirmButtonText: 'Да',
						cancelButtonText: 'Отмена'
					}).then(async ({ value }) => value && await store.deleteRecord(data))
				}
			}
		}

		const activeRow = (position) => {
			store.setPosition(position);

			emit('active', store.data.rows[position]);
		}

		const home = () => activeRow(0);

		const end = () => activeRow(store.data.rows.length - 1);

		const onKeyDown = (event) => {
			switch (event.keyCode) {
				case 13://enter
					selectRow();

					break;

				case 45://insert
					addRow();

					break;

				case 46://delete
					deleteRow();

					break;

				case 37://left
					home();

					event.preventDefault();

					break;

				case 39://right
					end();

					event.preventDefault();

					break;

				case 38://up
					if (store.data.position != null && store.data.position > 0) {
						activeRow(store.data.position - 1);

						event.preventDefault();
					}
					break;

				case 40://down
					if (store.data.position != null && store.data.position < store.data.rows.length - 1) {
						activeRow(store.data.position + 1);

						event.preventDefault();
					}
					break;

				case 36://home
					home();

					event.preventDefault();

					break;

				case 35://end
					end();

					event.preventDefault();

					break;
			}
		}

		const contextMenuBody = (event, row, key) => {
			const items = [];

			if (isReadonly.value && settings.view) items.push({
				icon: <i class="icon-eye"></i>,
				caption: 'Просмотр',
				onClick: () => editRow()
			})

			if (!isReadonly.value && access.update) items.push({
				icon: <i class="icon-pencil"></i>,
				caption: 'Изменить',
				onClick: () => editRow()
			})

			if (!isReadonly.value && access.create) items.push({
				icon: <i class="icon-add-to-list"></i>,
				caption: 'Добавить',
				onClick: () => addRow()
			})

			if (!isReadonly.value && access.delete) items.push({
				icon: <i class="icon-minus3"></i>,
				caption: 'Удалить',
				onClick: () => deleteRow()
			})

			const menuItems = typeof settings.contextMenuBody == 'function' ? settings.contextMenuBody(event, row, key) : settings.contextMenuBody;

			return menuItems.length > 0 ? menuItems : items;
		}

		onMounted(async () => {
			if (!store.model.subtable) {
				stateStore.state.load = true;

				store.fetchOptions['filters'] = settings.filters;

				if (settings.id) store.fetchOptions.id = settings.id;
				if (settings.owner) store.fetchOptions.owner = settings.owner;

				// store.fetchOptions.limit = settings.limit;

				await store.fetchData();

				stateStore.state.load = false;
			}

			settings.onCreate(store);
		});

		return {
			store,
			onKeyDown,
			settings,
			selectRow,
			activeRow,
			isReadonly,
			addRow,
			access: computed(() => store.model.access),
			contextMenuBody
		}

	}
})
</script>

<style>
</style>