import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import "@/assets/icons/icomoon/styles.min.css";
import 'mosha-vue-toastify/dist/style.css';
import '@/scss/styles.scss'

import VFocus from "./directives/VFocus";
import VClickaway from "./directives/VClickaway";

import DBForm from "@/components/DB/DBForm/index.vue";
import DBPanel from "@/components/DB/DBPanel/index.vue";

import InputField from "@/components/InputField/index.vue";
import DbField from "@/components/DbField/index.vue";
import DbTable from "@/components/DbTable/index.vue";
import UploadFiles from "@/components/UploadFiles.vue";
import popup from "@/components/popup.vue";

createApp(App)
	.use(store)
	.use(router)

	.component("DBForm", DBForm)
	.component("DBPanel", DBPanel)
	.component("InputField", InputField)
	.component("DbField", DbField)
	.component("DbTable", DbTable)
	.component("UploadFiles", UploadFiles)
	.component("popup", popup)

	.directive('focus', VFocus)
	.directive('clickaway', VClickaway)

	.mount('#app');
