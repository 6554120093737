import { getSchema } from '@/tables';

interface Access {
	create: boolean;
	delete: boolean;
	read: boolean;
	update: boolean;
};

class Model {
	public key: string = '';
	public notion: string = '';
	public description: string = '';

	public ownerField: string = '';
	public depends: string = '';

	public subtable: false = false;

	public access: Access = {
		create: true,
		read: true,
		update: true,
		delete: true
	}

	public fields: any = {};

	createModel(schema: any) {
		this.key = schema.key ? schema.key : '';
		this.notion = schema.notion ? schema.notion : '';
		this.description = schema.description ? schema.description : this.key;
		this.ownerField = schema.ownerField ? schema.ownerField : '';
		this.depends = schema.depends ? schema.depends : '';
		this.subtable = schema.offLine ? schema.offLine : false;

		this.fields = {};

		this.access = {
			create: true,
			read: true,
			update: true,
			delete: true
		}

		if (schema.access) {
			this.access.create = !!schema.access?.create;
			this.access.read = !!schema.access?.read;
			this.access.update = !!schema.access?.update;
			this.access.delete = !!schema.access?.delete;
		}

		Object.assign(this.fields, this.loadFields(schema.fields));
	}

	loadFields(fields: any): any {
		if (this.key && !fields[this.key]) {
			this.fields[this.key] = {
				[this.key]: {
					description: 'id',
					type: 'UUID'
				}
			}
		}

		for (const key in fields) {
			const field = Object.assign({}, fields[key]);

			if (field.type) {
				if (typeof field.type === 'object') {
					if (typeof field.type.table == 'string') {
						const schema = getSchema(field.type.table);

						field.type.model = new Model;
						field.type.model.createModel(schema);

					} else if (typeof field.type.table == 'object') {
						field.type.model = new Model;
						field.type.model.createModel(field.type.table);
					}

					if (field.type.model) {
						field.type.model.subtable = true;

						if (field.depends) field.type.model.depends = field.depends;

						field.type.model.access =
							(this.access.read && (this.access.create || this.access.update)) ?
								{
									create: true,
									read: true,
									update: true,
									delete: true
								} : {
									create: false,
									read: this.access.read,
									update: false,
									delete: false
								};
					}
				}
			}

			this.fields[key] = field;
		}
	}
}

export default Model;
