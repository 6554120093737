const tables: any = {}

export const schemas: any = {};

export const loadSchemas = (dbSchemas: any) => {
	for (const key in schemas) delete schemas[key];

	for (const key in dbSchemas) {
		const table: any = tables[key] ? tables[key] : {};

		schemas[key] = Object.assign({}, dbSchemas[key], table);
	}
}

export const getSchema = (name: string) => {
	const schema = schemas[name];

	return schema ? Object.assign({}, schema) : tables[name] ? Object.assign({ offline: true }, tables[name]) : null;
};
