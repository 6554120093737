import { reactive, watch } from 'vue';

import { mergeDeep } from '@/helpers/utils';
import Bus from '@/core/bus';
import api from '@/core/api';

import Store from "./store";
import { getSchema } from '@/tables';

class DBStore extends Store {
	public data: any = reactive({ rows: [], position: -1 });

	public ownerData: any = null;

	public fetchOptions = reactive({
		id: null,
		owner: null,//id - зависимого поля
		page: 0,
		pages: 0,
		limit: 0,
		filters: [],
		orderby: [],
		searchtext: ''
	});

	constructor(public name: string, config: any = {}) {
		super();

		const schema = getSchema(name);

		if (schema) {
			this.model.createModel(schema);

			this.createState(config);
		}

		Bus.$on('save-data', (saveData: any) => {
			if (saveData.table == name) {
				if (saveData.newrecord) {
					if (this.model.subtable) {
						this.push(saveData.data);
					} else {
						this.unshift(saveData.data);
					}
				} else {
					this.refreschData(saveData.data);
				}
			}
		});

		watch(
			() => this.fetchOptions.page,
			async () => await this.fetchData()
		)

		watch(
			() => this.fetchOptions.limit,
			async () => {
				this.fetchOptions.page = 0;
				await this.fetchData();
			}
		)

		watch(
			() => this.fetchOptions.orderby,
			async () => await this.fetchData()
		)

		watch(
			() => this.fetchOptions.searchtext,
			async () => {
				this.fetchOptions.page = 0;
				await this.fetchData()
			}
		)
	}

	setPosition(position: number) {
		this.data.position = this.data.rows.length > 0 && position < this.data.rows.length ? position : -1;
	}

	loadData(data: any = []) {
		this.data.rows = [];

		for (const row of data) {
			this.data.rows.push(this.createData(row));
		}

		this.setPosition(0);

		this.state.loaded = true;
	}

	refreschData(data: any, setPosition: boolean = false) {
		const key = this.model.key;

		for (const row in this.data.rows) {
			if (this.data.rows[row][key] == data[key]) {
				if (setPosition) this.setPosition(Number(row));

				return Object.assign(this.data.rows[row], data);
			}
		}

		return null;
	}

	push(data: any) {
		if (this.model.ownerField && this.model.depends && this.ownerData && this.ownerData[this.model.depends]) {
			this.owner = this.ownerData[this.model.depends];
		}

		const result = this.refreschData(data, true);
		if (result) return result;

		const _data = this.createData(data);

		this.data.rows.push(_data);
		this.setPosition(this.data.rows.length - 1);

		return _data;
	}

	unshift(data: any) {
		if (this.model.ownerField && this.model.depends && this.ownerData && this.ownerData[this.model.depends]) {
			this.owner = this.ownerData[this.model.depends];
		}

		const result = this.refreschData(data, true);
		if (result) return result;

		const _data = this.createData(data);

		this.data.rows.unshift(_data);
		this.setPosition(0);

		return _data;
	}

	clear() {
		this.data.rows.splice(0, this.data.rows.length);
		this.data.position = -1;
	}

	deleteRec(position: number) {
		this.data.rows.splice(position, 1);

		this.setPosition(position > this.data.rows.length - 1 ? this.data.rows.length - 1 : position);
	}

	async deleteRecord(data: any) {
		const key = data[this.model.key];

		if (key) {
			for (const row in this.data.rows) {
				const position: number = +row;

				if (key == this.data.rows[position][this.model.key]) {
					if (this.model.subtable) {
						this.deleteRec(position);
					} else {
						const response: any = await api.dbQuery({
							table: this.name,
							method: 'delete',
							data: {
								[this.model.key]: key
							}
						});

						if (response.status == 200) this.deleteRec(position);
					}
				}
			}
		}
	}

	currentData() {
		return this.data.position != -1 && this.data.rows.length > 0 ? this.data.rows[this.data.position] : null;
	}

	fetchParams(options = {}) {
		const params = mergeDeep({}, this.fetchOptions, options);

		if (typeof params.filters == 'function') params.filters = params.filters(this.data);

		delete params.pages;

		if (!params.fields && this.state.fields) params.fields = this.getFetchFields();

		if (!params.id) delete params.id;
		if (!params.owner) delete params.owner;
		if (!params.limit) delete params.limit;
		if (!params.limit && !params.page) delete params.page;
		if (!params.searchtext) delete params.searchtext;

		if (params.filters.length == 0) delete params.filters;
		if (params.orderby.length == 0) delete params.orderby;

		return params;
	}

	async fetchData(options: any = {}) {
		if (!this.model.subtable) {
			const params = this.fetchParams(options);

			const query = mergeDeep({
				table: this.name,
				method: 'read',
				params
			});

			const response: any = await api.dbQuery(query);

			if (response.status == 200) {
				const rows = response.data?.data?.rows;

				if (rows) {
					this.loadData(rows);

					if (this.fetchOptions.limit)
						this.fetchOptions.pages = Math.ceil(response.data.data.rowCount / this.fetchOptions.limit);
				}
			}
		}

		return this.data.rows;
	}

	dataForSave(data: any): any {
		return data.map((row: any) => this.getData(row));
	}
}

export default DBStore;
