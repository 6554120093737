import API from '@/core/api';

export default {
	token(state: any, value: any) {
		if (value) {
			API.token.set(value);
		} else {
			API.token.clear();
		}

		state.token = value;
	},

	user(state: any, value: any) {
		state.user = value;
	},

	timeout(state: any, value: any) {
		state.timeout = value;
	},

	messages(state: any, value: any) {
		state.messages = value;
	},

	online(state: any, value: any) {
		state.online = value;
	},

	locale(state: any, value: any) {
		state.locale = value
	},

	loaded(state: any, value: any) {
		state.loaded = value;
	},

	setConstants(state: any, value: any) {
		state.constants = value;
	}
}
