<template>
	<div class="page-wrapper">
		<header id="header" :class="['header', hideMenu ? 'header--hidden' : '']">
			<div class="header__top">
				<div class="header__logo">
					<img src="/img/logo.svg" alt="ТюмГУ" class="img-responsive" />
				</div>
				<button class="header__toggle" @click="toggleHideMenu()"></button>
				<button class="header__mobile-menu" @click="toggleMobileMenu()"></button>
				<button class="header__mobile-menu-close" @click="toggleMobileMenu()" v-if="mobileMenu"></button>
			</div>
			<nav :class="['header__menu', mobileMenu ? 'header__menu--open' : '']">
				<ul>
					<li :class="{active: this.$route.path=='/questionnaire'}">
						<router-link to="/questionnaire">
							<div class="header__menu-icon">
								<img src="/img/header__menu__icon-1.svg" alt class="img-responsive" />
							</div>
							<span>Анкетные данные</span>
						</router-link>
					</li>
					<li :class="{active: this.$route.path=='/statements'}">
						<router-link to="/statements">
							<div class="header__menu-icon">
								<img src="/img/header__menu__icon-2.svg" alt class="img-responsive" />
							</div>
							<span>Заявления</span>
						</router-link>
					</li>
				</ul>
			</nav>
			<div :class="['header__footer', mobileMenu ? 'header__footer--open' : '']">
				<div v-if="constants.statementuid" class="mb-1">
					<div class="uid">{{constants.statementuid}}</div>
					<small style="font-size:10px;">Ваш UID\СНИЛС</small>
					<br />
					<small style="font-size:10px;">
						(для поиска в
						<a href="https://ratings.utmn.ru" class="rating" target="_blank">рейтинге</a>)
					</small>
				</div>
				<div class="mb-1">
					<a href="https://youtu.be/tieMuiu34wQ" target="_blank" class="rating">Видео инструкция по заполнению</a>
				</div>
				<br />По всем вопросам, возникающим в процессе заполнения, необходимо обращаться в приемную комиссию ТюмГУ
				<br />Телефон: 8 800 700 05 53
				<br />e-mail: 597759@utmn.ru
				<hr />© 2024
				<br />ФГАОУ ВО Тюменский государственный университет
			</div>
		</header>
		<main class="main">
			<div id="main__header" class="main__header">
				<div class="main__header-container">
					<div class="main__header-title">
						<h1>{{pageTitle}}</h1>
					</div>
					<div class="main__header-controls">
						<user-messages></user-messages>
						<user-menu></user-menu>
					</div>
				</div>
			</div>
			<div id="profile" class="main__wrapper">
				<slot v-if="loaded" />
			</div>
		</main>
	</div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { pageTitle, hideMenu, mobileMenu } from "@/layouts/layouts";

import stateStore from '@/store'

import UserMessages from './UserMessages.vue';
import UserMenu from './UserMenu.vue';

export default defineComponent({
	name: "Default",

	components: {
		UserMessages, UserMenu
	},

	setup() {
		const toggleHideMenu = () => {
			hideMenu.value = !hideMenu.value;
		}

		const toggleMobileMenu = () => {
			mobileMenu.value = !mobileMenu.value;
		}

		return {
			loaded: computed(() => stateStore.state.loaded),
			constants: computed(() => stateStore.state.constants),
			hideMenu,
			mobileMenu,
			toggleHideMenu,
			toggleMobileMenu,
			pageTitle
		}
	}
})

</script>

<style scoped>
.rating {
	color: #ffffff;
	text-decoration: underline;
	font-size: 12px;
}

.rating:hover,
.rating:focus {
	color: #ffffff;
	text-decoration: underline;
}
</style>
