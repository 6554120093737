<template>
	<template v-for="panel in panels" :key="panel.key">
		<div class="popup popup--open">
			<button type="button" class="popup__close--mobile" @click.prevent="closePanel(panel)" v-if="panel.closeable"></button>
			<div class="popup__body" :style="panel.width ? `width: ${panel.width}`: ''">
				<div class="popup__header">
					<div class="popup__title">{{panel.caption}}</div>
					<button type="button" class="popup__close" @click.prevent="closePanel(panel)" v-if="panel.closeable"></button>
				</div>
				<div class="mb-1">
					<component :is="panel.component" :ref="`panel_${panel.key}`"></component>
				</div>
				<div class="popup__controls">
					<div class="popup__controls-btn" v-for="(button, key) of panel.buttons" :key="key">
						<button type="button" class="btn" @click.prevent="()=>button.onClick(panel)">{{button.caption}}</button>
					</div>
					<div class="popup__controls-btn">
						<button type="button" class="btn btn--secondary" @click.prevent="closePanel(panel)" v-if="panel.closeable">{{panel.closeBtnText}}</button>
					</div>
				</div>
			</div>
		</div>
	</template>
</template>

<script>

import { computed } from 'vue';

import { panels, closePanel } from "@/layouts/layouts";

export default {
	updated() {
		setTimeout(() => {
			for (const panel of panels.value) {
				if (this.$refs[`panel_${panel.key}`] && !panel.ref) {
					panel.ref = this.$refs[`panel_${panel.key}`][0];

					panel.onShow(panel);
				}
			}
		}, 100);
	},

	setup() {
		return {
			panels: computed(() => panels.value.filter(panel => panel.modal)),
			closePanel
		}
	}
}

</script>
