<template>
	<div class="page-wrapper">
		<main class="index">
			<h1 class="locking__message">Уважаемые абитуриенты, личный кабинет для поступающих в Гимназию ТюмГУ будет доступен с 17 июня 2024 года, для поступающих на программы среднего профессионального и высшего образования с 20 июня 2024 года.</h1>
		</main>
	</div>
</template>

<script>
export default {
	name: "Empty"
}
</script>

<style scoped>
.pageContainer {
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	min-height: calc(100vh - 48px);
}

.locking__message {
	text-align: center;
	width: 700px;
	margin: 33px auto 0;
	border: 2px solid #f4f7fc;
	border-radius: 3px;
	padding: 12px 20px;
	line-height: 150%;
	color: #fff;
	background-color: #2274a3;
}
</style>