import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru.json'

TimeAgo.addDefaultLocale(ru);

const timeAgo = new TimeAgo('ru-RU');

export const timeago: any = (datatime: any) => timeAgo.format(new Date(datatime));

export function copyObject(data: any, fields: any = null, defaults: any = null): any {
	const result: any = {};

	if (fields) {
		for (const key of fields) {
			if (data.hasOwnProperty(key)) {
				result[key] = data[key];
			}
		}
	} else {
		Object.assign(result, data);
	}

	if (defaults) Object.assign(result, defaults);

	return result;
}

export function genGUID(): string {
	const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
	return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function checkSnils(value: string): boolean {
	if (!value) return false;

	const snils: string = value.replace(/-/g, "");

	const res: number = Number(snils.substr(-2, 2));

	let sum: number = 0;

	for (let i: number = 0; i < 9; i++) {
		sum += Number(snils[i]) * (9 - i);
	}

	if (sum > 101) sum %= 101;

	if (sum == 101 || sum == 100) sum = 0;

	return sum == res;
}

export function checkEmail(email: string): boolean {
	return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
}

export const addArr: any = (array: any, item: any, field: any) => {
	const findElement = array.find((element: any) => element[field] == item[field]);
	if (findElement) return findElement;

	array.push(item);

	return item;
}

export function deepClone(obj: any): any {
	if (Array.isArray(obj)) {
		return obj.map((val) => deepClone(val))
	} else if (typeof obj === "object") {
		const res: any = {}
		for (let k in obj) {
			res[k] = deepClone(obj[k])
		}
		return res
	} else {
		return obj;
	}
}

/**
 * 
 * @param n 
 * @param fn 
 * @param immed 
 * @returns 
 */
export const debounce = (n: number, fn: (...params: any[]) => any, immed: boolean = false) => {
	let timer: number | undefined = undefined;

	return function (this: any, ...args: any[]) {
		if (timer === undefined && immed) {
			fn.apply(this, args);
		}

		clearTimeout(timer);

		timer = setTimeout(() => fn.apply(this, args), n);

		return timer;
	}
};

export const dataURItoBlob = (dataURI: string) => {
	const byteString = atob(dataURI.split(',')[1]);
	const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mimeString });
}

export const srcToBlob = (src: string) => {
	return new Promise((resolve) => {
		const img = new Image;
		img.src = src;
		const c = document.createElement("canvas");
		const ctx: any = c.getContext("2d");
		img.onload = function (this: any) {
			c.width = this.naturalWidth;
			c.height = this.naturalHeight;
			ctx.drawImage(this, 0, 0);
			c.toBlob((blob) => resolve(blob), "image/jpeg", 0.75);
		};
	})
}

//Прием на дополнительное образование
export const isHighSchool = (guid: string) => guid == 'fb475af9-b3e6-11e7-80e0-005056972aa1';

//СПО
export const isSPO = (guid: string) => guid == '1e325f1f-337d-11e6-80d7-005056972aa1';

//Бакалавриат
export const isBachelor = (guid: string) => guid == '1e325f1e-337d-11e6-80d7-005056972aa1';

//Магистратура
export const isMagistracy = (guid: string) => guid == '1e325f1d-337d-11e6-80d7-005056972aa1';

//Аспирантура
export const isTraineeship = (guid: string) => guid == '1e325f20-337d-11e6-80d7-005056972aa1';

export const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item);

export const mergeDeep = (target: any, ...sources: any): any => {
	if (!sources.length) return target;

	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, {
					[key]: {}
				});
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, {
					[key]: source[key]
				});
			}
		}
	}

	return mergeDeep(target, ...sources);
}

export const firstLetterToUppercase = (value: string) => value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const b64toBlob = (b64Data: any, contentType: string = '', sliceSize: number = 512) => {
	const byteCharacters = atob(b64Data);

	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	return new Blob(byteArrays, { type: contentType });
}
