import axios from "axios";
import LocalStorageToken from "./LocalStorageToken";
import { config } from "@/config";

import { createToast } from 'mosha-vue-toastify';

let showerror = true;

class API {
	public token: LocalStorageToken;

	constructor() {
		this.token = new LocalStorageToken("token");

		axios.interceptors.response.use((response) => response, (error) => error.response);
	}

	getHeaders() {
		let token = this.token.get();

		if (!token) return {};

		return {
			authorization: token,
			"Access-Control-Allow-Origin": "*"
		};
	}

	/**
	 * Запрос к серверу
	 * @param path 
	 * @param opts 
	 * @param toast 
	 * @returns 
	 */
	query(path: string, opts: any = {}, toast: boolean = true) {
		opts.timeout = 5 * 60 * 1000;

		opts.headers = {
			...this.getHeaders(),
			...opts.headers,
		};

		opts.url = `${config.api}${path}`;

		const showError = (message: any) => {
			if (showerror && toast) {
				showerror = false;
				createToast(message, {
					timeout: 2000, hideProgressBar: true, transition: 'zoom', type: 'danger'
				});
			}
		}

		return new Promise(async (resolve, reject) => {
			axios(opts)
				.then(response => {
					if (response && response.status) {
						if (response.status == 200 || response.status == 201) {
							showerror = true;

							resolve(response);
						} else {
							response.data.message = response?.data?.message ? response.data.message : `Ошибка сервера: ${response.status} ${response.statusText}`;

							showError(response.data.message);

							resolve(response);
						}
					} else {
						throw new Error('Ошибка подключения к серверу!');
					}
				})
				.catch((response) => {
					showError(response.message);

					reject(response);
				})
		});
	}

	mainMenu = () => this.query(`/system/mainmenu`);
	constants = () => this.query(`/system/constants`);

	login = (data: any) => this.query(`/auth/login`, { data, method: "put" }, false);
	regEmail = (email: string) => this.query(`/auth/registrationemail`, { data: { email }, method: "put" }, false);
	passwordRestore = (token: string, password: string) => this.query(`/auth/passwordrestore`, { data: { token, password }, method: "put" }, false);
	passwordSet = (data: any) => this.query(`/auth/passwordset`, { data, method: "put" }, false);
	registration = (data: any) => this.query(`/auth/registration`, { data, method: "put" }, false);
	confirmEmail = (token: string) => this.query(`/auth/emailconfirm`, { data: { token }, method: "put" }, false);
	passwordRecovery = (email: string) => this.query(`/auth/passwordrecovery`, { data: { email }, method: "put" }, false);
	status = () => this.query(`/auth/status`);
	proceedToken = () => this.query(`/auth/tokenproceed`);

	userProfile = (id: string) => this.query(`/users/${id}`);

	dbQuery = (query: any) => this.query(`/db/query`, { data: query, method: "put" });
	dbSchemas = () => this.query(`/db/schemas`);

	formToServer = (form: any, options: any = {}) => this.query(
		'/db/form',
		Object.assign(
			{
				method: "put",
				data: form,
				headers: { 'Content-Type': 'multipart/form-data' }
			},
			options
		)
	);

	profileSetAvatar = (avatar: any) => this.query(`/profile/setavatar`, { data: { avatar }, method: "post" });
	profileSetCover = (cover: any) => this.query(`/profile/setcover`, { data: { cover }, method: "post" });

	fileUpload = (data: any, file: any, description: any = null) => {
		const formData = new FormData();
		formData.append('data', JSON.stringify(data));
		if (file) formData.append(data['name'], file);
		if (description) formData.append('description', description);

		return this.query(`/files/upload`, {
			method: "post",
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		});
	};

	filesDelete = (id: string) => this.query(`/files/${id}`, { method: "delete" });
	filesDeleteMark = (id: string) => this.query(`/files/deletemark/${id}`, { method: "delete" });
	filesRead = (owner: string) => this.query(`/files/list/${owner}`, { method: "get" });

	blobToServer = (owner: any, blob: any, filename: string, options: any = {}) => {
		var formData = new FormData();
		formData.append(filename, blob);
		formData.append('owner', owner);

		return this.query('/files/uploadblob', Object.assign({
			method: "post",
			data: formData,
			headers: { 'Content-Type': 'multipart/form-data' }
		}, options));
	};

	commentsRead = (owner: string) => this.query(`/comments/${owner}`, { method: "get" });
	commentsCreate = (owner: string, message: string) => this.query(`/comments/add`, { data: { owner, message }, method: "post" });
	commentsUpdate = (id: string, message: string) => this.query(`/comments/edit/${id}`, { data: { message }, method: "put" });
	commentsDelete = (id: string) => this.query(`/comments/${id}`, { method: "delete" });

	like = (owner: string) => this.query(`/likes/like/${owner}`);
	dislike = (owner: string) => this.query(`/likes/dislike/${owner}`);

	messagesRead = () => this.query(`/messages`);
	messagesDelete = (id: string) => this.query(`/messages/${id}`, { method: "delete" });

	imagesRead = (owner: string) => this.query(`/images/list/${owner}`, { method: "get" });

	reportsTableExport = (table: string, params: any = {}) => this.query(`/reports/table/export/${table}`, { data: params, method: "post", responseType: 'blob' });

	reportsStatements = (name: string, params: any = {}) => this.query(`/reports/statements/${name}`, { data: params, method: "post", responseType: 'blob' });

	newStatement = (reception_campaign: string) => this.query(`/abiturient/new/${reception_campaign}`, { method: "get" });
	questionnaire = () => this.query(`/abiturient/questionnaire`, { method: "get" });

	statements = () => this.query(`/abiturient/statements`, { method: "get" });

	addContractSetPlan = (competition_group: string) => this.query(`/abiturient/addcontractsetplan/${competition_group}`, { method: "get" });

	setStatusHistory = (data: any) => this.query(`/abiturient/setstatushistory`, { data, method: "post" });

	confirmComment = (statement: string, comment: string) => this.query(`/abiturient/confirmcomment/${statement}/${comment}`, { method: "get" });
	confirmContract = (statement: string, status: number) => this.query(`/abiturient/confirmcontract/${statement}/${status}`, { method: "get" });
	confirmStatement = (statement: string, status: number) => this.query(`/abiturient/confirmstatement/${statement}/${status}`, { method: "get" });

	checkStatement = (data: any) => this.query(`/abiturient/checkstatement`, { data, method: "post" });
	checkRecall = (data: any) => this.query(`/abiturient/checkrecall`, { data, method: "post" });
	checkConsent = (data: any) => this.query(`/abiturient/checkconsent`, { data, method: "post" });
}

export default new API();
