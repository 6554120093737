import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = {
  key: 1,
  class: "icon-minus3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($setup.contextMenu.show)
    ? (_openBlock(), _createElementBlock("ul", {
        key: 0,
        class: "context-menu dropdown-menu show",
        style: _normalizeStyle({top: $setup.contextMenu.top, left: $setup.contextMenu.left})
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.contextMenu.items, (item, key) => {
          return (_openBlock(), _createElementBlock("li", { key: key }, [
            _createElementVNode("a", {
              href: "#",
              onClick: _withModifiers(item.onClick, ["prevent"])
            }, [
              (item.icon)
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(item.icon), { key: 0 }))
                : (_openBlock(), _createElementBlock("i", _hoisted_2)),
              _createTextVNode(" " + _toDisplayString(item.caption), 1)
            ], 8, _hoisted_1)
          ]))
        }), 128))
      ], 4))
    : _createCommentVNode("", true)
}