import { createStore } from 'vuex'

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import API from '@/core/api';

const state = {
	token: API.token.get(),
	user: {
		id: null,
		roles: [],
		name: null
	},
	timeout: 0,
	messages: [],
	constants: {},
	online: false,
	load: false,
	locale: 'ru',
	loaded: false
}

export default createStore({
	state,
	mutations,
	actions,
	getters
})
