import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import stateStore from "@/store"
import { pageTitle } from "@/layouts/layouts";

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: 'home',
		// component: () => import('@/views/Home.vue')
		redirect: '/questionnaire'
	},
	{
		path: '/questionnaire',
		component: () => import('@/views/Questionnaire.vue'),
		meta: {
			layout: 'default-layout',
			roles: ['abiturient'],
			title: 'Анкетные данные'
		}
	},
	{
		path: '/messages',
		component: () => import('@/views/messages.vue'),
		meta: {
			layout: 'default-layout',
			roles: ['abiturient'],
			title: 'Сообщения'
		}
	},
	{
		path: '/statements',
		component: () => import('@/views/Statements.vue'),
		meta: {
			layout: 'default-layout',
			roles: ['abiturient'],
			title: 'Заявления'
		}
	},
	{
		path: '/statements/:id',
		component: () => import('@/views/Statement/index.vue'),
		meta: {
			layout: 'default-layout',
			roles: ['abiturient'],
			title: 'Заявление'
		}
	},
	{
		path: '/vuzonline',
		component: () => import('@/views/vuzonline.vue'),
		meta: {
			layout: 'default-layout',
			roles: ['abiturient'],
			title: 'Подача заявлений граждан РФ'
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/auth/Login.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/regemail',
		name: 'regemail',
		component: () => import('@/views/auth/RegEmail.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/passwordrecovery',
		component: () => import('@/views/auth/PasswordRecovery.vue'),
		meta: {
			layout: "empty-layout"
		}
	},
	{
		path: '/passwordrecovery/:token',
		component: () => import('@/views/auth/PasswordRecovery.vue'),
		meta: {
			layout: 'empty-layout'
		}
	},
	{
		path: '/registration/:token',
		component: () => import('@/views/auth/Registration.vue'),
		meta: {
			layout: "empty-layout"
		}
	},
	{
		path: '/404',
		component: () => import('@/views/Page404.vue'),
		meta: {
			layout: "layout-404"
		}
	},
	{
		path: '/:pathMatch(.*)',
		// component: () => import('@/views/Page404.vue')
		redirect: '/questionnaire'
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

router.beforeEach(async (to, from) => {
	//Прописывать в роутах роли и проверять доступ при переходах
	// closeAllPanels();

	//Получаем статус пользователя
	const user: any = stateStore.state.user.id ? stateStore.state.user : await stateStore.dispatch('status');

	//Если в метаданных прописаны роли, то производим проверку на доступ, если не указана ни одна роль - доступ запрещен
	if (to.meta.roles) {
		const roles: any = to.meta.roles;

		for (const routeRole of roles) {
			if (user.roles.includes(routeRole)) return true;
		}

		return { path: '/login' };
	} else {
		return true;
	}
})

router.afterEach((to, from) => {
	const win: any = window;

	win.ym(65125612, 'hit', to.fullPath);

	pageTitle.value = to.meta.title ? to.meta.title : null;
})

export default router
