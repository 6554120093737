<template>
	<div :class="['popup', 'popup--open']" v-cloak>
		<button class="popup__close--mobile" @click="closePopup()"></button>
		<div class="popup__body" style="width: 500px">
			<div class="popup__header">
				<div class="popup__title">Время истекает</div>
				<button class="popup__close" @click="closePopup()"></button>
			</div>
			<div class="popup-achievements" v-cloak>
				<div class="form__row mb-1">
					<p>Время сессии истекает. Продолжить соединение?</p>
				</div>
				<div class="popup__controls">
					<div class="popup__controls-btn">
						<button class="btn" @click="proceed()">Продолжить</button>
					</div>
					<div class="popup__controls-btn" style="width: 160px;">
						<button class="btn btn--secondary" @click="logout()">Выход ({{timeStr}})</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";

export default {
	data() {
		return {
			timeout: 0
		};
	},

	computed: {
		timeStr() {
			return dayjs(this.timeout * 1000).format("mm:ss");
		}
	},

	mounted() {
		this.timer = setInterval(() => {
			const date = new Date;

			this.timeout = ~~((this.$store.state.user.exp - date.getTime()) / 1000);

			if (this.timeout < 1) this.logout();
		}, 1000);
	},

	unmounted() {
		clearInterval(this.timer);
	},

	methods: {
		logout() {
			this.$store.dispatch("logout");
		},

		proceed() {
			this.$store.dispatch("proceedToken");
		}
	}
};
</script>
