<script>
import { computed, defineComponent } from 'vue';

import DBStoreRecord from '@/core/db_store_record';

export default defineComponent({
	props: {
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: null
		},
		readonlyForm: {
			type: Boolean,
			default: null
		}
	},

	setup(props, { slots }) {
		return () => {
			const field = props.form.store.model.fields[props.field];

			if (!props.form.store.data[props.field]) return null;

			return props.form.store.data[props.field].map((data, key) => {
				const storeRow = new DBStoreRecord(field.table);
				storeRow.model = field.type.model;

				storeRow.createState(props.form.store.state.fields[props.field]);

				storeRow.files = props.form.store.files;
				storeRow.state.readonly = typeof props.readonlyForm == 'boolean' ? props.readonlyForm : props.form.store.state.readonly;

				storeRow.data = data;

				storeRow.setWatching();

				return slots.default({
					data,
					form: {
						key,
						store: storeRow,
						addValidation: props.form.addValidation,
						delValidation: props.form.delValidation,
						nextControl: props.form.nextControl,
						save: props.form.save,
						isModif: props.form.isModif,
						isNew: props.form.isNew,
						readonly: computed(() => typeof props.readonlyForm == 'boolean' ? props.readonlyForm : props.form.store.state.readonly)
					}
				});
			})
		}
	}
})

</script>

<style>
</style>