import { ref } from 'vue';

import { genGUID } from '@/helpers/utils';

export const hideMenu = ref(false);
export const mobileMenu = ref(false);

export const pageTitle: any = ref(null);

const activePanels: any = [];

export const panels = ref<any>([]);

export const thisPanel = ref(null);

export const caption = ref<string>('');

export const setCaption = (value: string) => caption.value = value;

export const openPanel = (panel: any) => {
	const _panel: any = Object.assign({
		modal: false,
		key: genGUID(),
		caption: '',
		component: null,
		center: true,
		buttons: ref([]),
		bodyStyle: {},
		dialogClass: {},
		dialogStyle: {},
		closeable: true,
		topPanel: true,
		bottomPanel: false,
		onCreate: () => { },
		onShow: () => null,
		onClose: (result: any = null) => result,
		closeBtnText: 'Закрыть'
	}, panel);

	//Если есть уже открыте модальные панели, то новая панель только модально
	for (const key in panels.value) {
		if (panels.value[key].modal) {
			_panel.modal = true;

			break;
		}
	}

	_panel.setButtons = (buttons: any) => _panel.buttons.value = buttons;
	_panel.close = (result: any = true) => result && closePanel(_panel, result);

	if (_panel.center) _panel.dialogClass['modal-dialog-centered'] = _panel.center;

	if (panel.width) _panel.dialogStyle['max-width'] = panel.width;

	if (panel.height) {
		_panel.bodyStyle['height'] = panel.height;
		_panel.bodyStyle['overflow'] = 'scroll';
		_panel.bodyStyle['overflow-x'] = 'hidden';
		_panel.bodyStyle['overflow-y'] = 'auto';
	}

	panels.value.push(_panel);

	activePanels.push(_panel.key);

	setTimeout(() => activePanel(_panel), 0);

	if (panels.value.filter((el: any) => el.modal).length > 0) {
		//Запретить прокрутку документа
		document.body.style.overflow = 'hidden';
	}

	return Object.assign(_panel, _panel.onCreate(_panel));
}

export const closePanel = (panel: any, result: any = null) => {
	const _panels = panels.value;

	for (const key in _panels) {
		if (_panels[key].key == panel.key && _panels[key].closeable) {
			const close = () => {
				for (const key in activePanels) {
					if (activePanels[key] == panel.key) {
						activePanels.splice(key, 1);

						break;
					}
				}

				if (activePanels.length) {
					const key = activePanels[activePanels.length - 1];

					for (const i in _panels) {
						if (_panels[i].key == key) {
							activePanel(_panels[i]);

							break;
						}
					}
				} else {
					setTimeout(() => activePanel(null), 0);
				}

				_panels[key].onClose(result);

				_panels.splice(key, 1);
			}

			if (_panels[key].onBeforeClose) {
				_panels[key].onBeforeClose(() => close())
			} else {
				close();
			}

			break;
		}
	}

	if (_panels.filter((el: any) => el.modal).length == 0) {
		//Вернуть прокрутку основной страницы если закрыты все модальные окна
		document.body.style.overflowX = 'hidden';
		document.body.style.overflowY = 'inherit';
	}

	if (_panels.length == 0) {
		activePanel(null);
	}
}

export const activePanel = (panel: any) => {
	const _panels = panels.value;

	if (panel) {
		for (const keyA in _panels) {
			if (_panels[keyA].key == panel.key) {
				if (!_panels[keyA].modal) thisPanel.value = _panels[keyA].key;

				for (const keyB in activePanels) {
					if (activePanels[keyB] == panel.key) {
						const _panel = activePanels.splice(keyB, 1);

						activePanels.push(_panel[0])

						break;
					}
				}

				break;
			}
		}
	} else {
		thisPanel.value = null;
	}
}

export const closeAllPanels = () => {
	panels.value = [];

	activePanel(null);

	caption.value = '';
}

export const getThisPanel = () => activePanels.length > 0 ? activePanels[activePanels.length - 1] : null;

document.body.addEventListener('keyup', (e: any) => {
	if (e.keyCode == 27 && activePanels.length > 0) {
		if (!(e.target.localName == "input" && e.target.type == 'file')) {

			e.stopPropagation();

			closePanel({
				key: activePanels[activePanels.length - 1]
			});
		}
	}
})
